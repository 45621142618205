'use client';

import { Menu } from 'lucide-react';
import Link from 'next/link';
import { AppLogo } from '~/components/layout/AppLogo';
import { Button, buttonVariants } from '~/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '~/components/ui/sheet';
import { Typo } from '~/components/ui/typography';
import { SiteConfig } from '~/lib/site-config';
import { Section } from './Section';
import { GET_STARTED_URL, LOGIN_URL } from './landing.const';

export type HeaderProps = {};

export const Header = (props: HeaderProps) => {
  const links = (
    <>
      <Link
        href="#pricing"
        className="font-caption transition hover:text-foreground"
      >
        Why {SiteConfig.name}
      </Link>
      <Link
        href="#pricing"
        className="font-caption transition hover:text-foreground"
      >
        Pricing
      </Link>
    </>
  );

  return (
    <Section as="header" className="flex items-center gap-4 md:gap-6 md:py-4">
      <div className="flex items-center gap-2">
        <AppLogo size={24} />
        <Typo variant="default" className="font-caption">
          {SiteConfig.name}
        </Typo>
      </div>

      <ul className="hidden items-center gap-4 text-muted-foreground md:flex">
        {links}
      </ul>
      <div className="flex-1" />
      <div className="hidden items-center gap-2 md:flex">
        <Link
          href={LOGIN_URL}
          className={buttonVariants({
            size: 'sm',
            variant: 'ghost',
          })}
        >
          Sign in
        </Link>
        <Link
          href={GET_STARTED_URL}
          className={buttonVariants({
            size: 'sm',
            variant: 'outline',
          })}
        >
          Get started
        </Link>
      </div>
      <div className="md:hidden">
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="outline" size="sm">
              <Menu size={16} />
              <span className="sr-only">Open menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="top" className="mx-2 mt-2 flex flex-col gap-6 px-2">
            <div className="flex items-center gap-2">
              <AppLogo size={24} />
              <Typo variant="default" className="font-caption">
                {SiteConfig.name}
              </Typo>
            </div>
            <ul className="flex flex-col gap-4 text-muted-foreground">{links}</ul>
            <div className="flex items-center gap-4">
              <Link
                href={LOGIN_URL}
                className={buttonVariants({
                  className: 'w-full',
                  size: 'sm',
                  variant: 'ghost',
                })}
              >
                Sign in
              </Link>
              <Link
                href={GET_STARTED_URL}
                className={buttonVariants({
                  className: 'w-full',
                  size: 'sm',
                  variant: 'outline',
                })}
              >
                Get started
              </Link>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </Section>
  );
};
