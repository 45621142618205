import type { ComponentPropsWithoutRef, ElementType } from 'react';
import { cn } from '~/lib/utils';

export type SectionProps = ComponentPropsWithoutRef<'div'> & {
  as?: ElementType;
  padding?: 'disable' | 'default' | 'lg' | 'xl';
  gap?: 'default' | 'lg' | 'xl';
};

export const Section = (props: SectionProps) => {
  const Comp = props.as ?? 'div';
  return (
    <div className="border-b border-dashed border-foreground/20">
      <Comp
        {...props}
        className={cn(
          'border-x border-inherit border-dashed bg-background px-4 lg:px-8 max-w-6xl lg:mx-12 xl:mx-auto md:mx-4 mx-2',
          {
            'py-2': props.padding === 'default',
            'py-20 lg:py-28': props.padding === 'lg',
            'py-24 lg:py-32': props.padding === 'xl',
          },
          {
            default: props.gap === 'default',
            'gap-8 md:gap-16 lg:gap-20': props.gap === 'lg',
          },
          props.className
        )}
      >
        {props.children}
      </Comp>
    </div>
  );
};
