'use client';

import { motion } from 'framer-motion';
import Image from 'next/image';
import { useState } from 'react';
import { Typo } from '~/components/ui/typography';
import MuxVideo from '~/features/mdx/mdx-component/MuxVideo';
import { cn } from '~/lib/utils';
import { Section } from './Section';

type Item = { id: number; title: string; description: string } & (
  | { video: string; type: 'video' }
  | { image: string; type: 'image' }
);

const ITEMS: Item[] = [
  {
    id: 1,
    title: 'Create bundle of products',
    description:
      'Create bundle of products to sell to your customers. You can add a discount to the bundle.',
    video: 's7LzqdtuvSD02dfbsuW9FNysmQJ1HzOZz9Y12EDRAkAQ',
    type: 'video',
  },
  {
    id: 2,
    title: 'Create discount',
    description:
      'Create discount for your customers. Add a discount to any bundle or product.',
    video: 'rrN02bJyaQTpJJ2Fwiqpt4CFz2KSDAO8y7ef7xe3XtmM',
    type: 'video',
  },
  {
    id: 3,
    title: 'Add upsell',
    description:
      'Add upsell after a purchase. Increase the avergage spending of your customers.',
    image: '/images/upsell.png',
    type: 'image',
  },
  {
    id: 4,
    title: 'Transform your student into affiliates',
    description:
      'Easily enable affiliates and transform your customers into your affiliates.',
    image: '/images/affiliates.png',
    type: 'image',
  },
];

export const MarketingFeatures = () => {
  const [selectedId, setSelectedId] = useState<number>(1);

  const handleItemClick = (id: number) => {
    setSelectedId(id);
  };

  const currentItem = ITEMS.find((item) => item.id === selectedId);

  return (
    <Section padding="disable" className="pb-8">
      <div className="relative flex items-start overflow-x-auto">
        {ITEMS.map((item) => (
          <button
            key={item.id}
            className={cn(
              'flex flex-col pt-4 lg:pt-6 px-4 lg:px-6 text-start gap-2 relative'
            )}
            onClick={() => handleItemClick(item.id)}
          >
            {selectedId === item.id ? (
              <motion.div
                layoutId="item-indicator"
                className={cn(
                  'h-0.5 w-full top-0 bg-primary absolute left-0 right-0'
                )}
              />
            ) : null}
            <Typo
              variant="default"
              className={cn('', {
                'text-primary': selectedId === item.id,
              })}
            >
              {item.title}
            </Typo>
            <Typo variant="muted" className="hidden lg:inline">
              {item.description}
            </Typo>
          </button>
        ))}
      </div>
      {currentItem?.type === 'image' ? (
        <Image
          width={500}
          height={500}
          className="mt-8 h-auto w-full border-2 border-border shadow-xl lg:mt-12"
          src={currentItem.image}
          alt="image"
        />
      ) : null}
      {currentItem?.type === 'video' ? (
        <MuxVideo id={currentItem.video} autoPlay={true} className="mt-8" />
      ) : null}
    </Section>
  );
};
