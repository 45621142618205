import type { z } from 'zod';
import { Card, CardContent, CardHeader, CardTitle } from '~/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/components/ui/table';
import { Typo } from '~/components/ui/typography';

export type ZodErrorHelperProps = {
  error: z.ZodError<Record<string, unknown>>;
  name?: string;
};

export const ZodErrorHelper = (props: ZodErrorHelperProps) => {
  return (
    <Card className="not-prose border-destructive bg-destructive/10">
      <CardHeader>
        <CardTitle>Error in component {props.name ?? '?'}</CardTitle>
      </CardHeader>
      <CardContent>
        <Table className="border">
          <TableHeader>
            <TableRow>
              <TableHead>Path</TableHead>
              <TableHead>Message</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {props.error.issues.map((issue, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Typo variant="code">{issue.path.join('.')}</Typo>
                </TableCell>
                <TableCell>
                  <Typo variant="small">{issue.message}</Typo>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
