'use client';

import { AlertCircle, Check, MailIcon, Square, Star, X } from 'lucide-react';
import { Badge } from '~/components/catalyst/badge-catalyst';
import { Typo } from '~/components/ui/typography';
import { ComparisonCard } from './Comparison';
import { Section } from './Section';

export const FinishCourses = () => {
  return (
    <>
      <Section as="main" padding="lg" gap="lg" className="relative flex flex-col">
        <div className="m-auto flex w-full max-w-xl flex-col items-center gap-2 text-center">
          <Badge size="lg" color="blue">
            One last thing...
          </Badge>
          <Typo variant="h1" as="h2">
            We help <b>your students</b> finish <b>your courses</b>
          </Typo>
          <Typo className="text-lg font-light text-muted-foreground">
            You know that 90% of teacher doesn't care about how much students finish
            their courses ? We do.
          </Typo>
        </div>
        <div className="m-auto grid w-full max-w-4xl grid-cols-1 gap-4 px-4 md:grid-cols-2 md:gap-8">
          <ComparisonCard variant="red">
            <Typo variant="large">When a student DOESN'T finish your courses</Typo>
            <Typo variant="muted" className="mt-2 text-red-200">
              Other plateforme focus on marketing and doesn't care about your
              students.
            </Typo>
            <ul className="mt-4 flex flex-col gap-2">
              <li className="flex items-center gap-2">
                <X className="text-red-500" />
                <span>Will never make you a review</span>
              </li>
              <li className="flex items-center gap-2">
                <X className="text-red-500" />
                <span>Will forgot about the quality of your content</span>
              </li>
              <li className="flex items-center gap-2">
                <X className="text-red-500" />
                <span>Will not buy any more courses</span>
              </li>
            </ul>
          </ComparisonCard>
          <ComparisonCard variant="green">
            <Typo variant="large">When a student finish your courses</Typo>
            <Typo variant="muted" className="mt-2 text-green-200">
              Codeline is optimized for develiering the best value to your students.
            </Typo>
            <ul className="mt-4 flex flex-col gap-2">
              <li className="flex items-center gap-2">
                <Check className="text-green-500" />
                <span>Will make you a beautiful review</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500" />
                <span>Will refer your courses to their friends</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500" />
                <span>Will buy your more courses</span>
              </li>
            </ul>
          </ComparisonCard>
        </div>
        <div className="m-auto flex w-full max-w-xl flex-col items-center gap-2 text-center">
          <Typo variant="h1" as="h2">
            Our feature to improve the delivery of your courses
          </Typo>
          <Typo className="text-lg font-light text-muted-foreground">
            We want your student get the success they deserve.
          </Typo>
        </div>

        <div className="mx-auto mt-8 max-w-2xl sm:mt-12 lg:mt-16 lg:max-w-4xl">
          <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-foreground">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-primary">
                    <feature.icon
                      aria-hidden="true"
                      className="size-6 text-primary-foreground"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-muted-foreground">
                  {feature.description}
                </dd>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </>
  );
};

const features = [
  {
    name: 'Weekly Email',
    description:
      'Receive an AI-generated summary of the week and encourage your students to complete the course.',
    icon: MailIcon,
  },
  {
    name: 'GitHub-like Progress',
    description:
      'Students can track their daily course completion progress, similar to GitHub contributions.',
    icon: Square,
  },
  {
    name: 'Completion Reminders',
    description:
      'If a user is inactive for a few days, we send reminders to motivate them to continue.',
    icon: AlertCircle,
  },
  {
    name: 'Best Platform UX',
    description:
      'We design a user experience with sound effects, quizzes, and an appealing UI to keep your students engaged.',
    icon: Star,
  },
];
