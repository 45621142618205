'use client';

import { Expand, Shrink } from 'lucide-react';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { cloneElement, createContext, useContext, useState } from 'react';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib/utils';

// Types
type CinemaContextType = {
  isCinema: boolean;
  toggleCinema: () => void;
};

type CinemaViewerProps = {
  children: ReactNode;
};

type CinemaViewerContainerProps = {
  children?: ReactNode;
  className?: string;
};

// Context
const CinemaContext = createContext<CinemaContextType | undefined>(undefined);

const useCinema = () => {
  const context = useContext(CinemaContext);
  if (!context) {
    throw new Error('useCinema must be used within a CinemaProvider');
  }
  return context;
};

// CinemaViewer Component
export function CinemaViewer({ children }: CinemaViewerProps) {
  const [isCinema, setIsCinema] = useState(false);

  const toggleCinema = () => setIsCinema(!isCinema);

  return (
    <CinemaContext.Provider value={{ isCinema, toggleCinema }}>
      {children}
    </CinemaContext.Provider>
  );
}

// CinemaViewerAction Component
export function CinemaViewerToggle({ children }: { children?: ReactElement }) {
  const { toggleCinema, isCinema } = useCinema();

  if (!children) {
    return (
      <Button
        variant="outline"
        className="size-7 p-0 text-muted-foreground hover:text-foreground"
        onClick={() => toggleCinema()}
      >
        {isCinema ? <Shrink size={14} /> : <Expand size={14} />}
      </Button>
    );
  }

  return cloneElement(children, { onClick: toggleCinema });
}

export const CinemaViewerActions = (props: PropsWithChildren) => {
  const { isCinema } = useCinema();
  return (
    <div
      className={cn('flex items-center px-2 py-0.5 gap-1', {
        'bg-background rounded-t-md border-t border-border': isCinema,
      })}
    >
      {props.children}
    </div>
  );
};

// CinemaViewerContainer Component
import { forwardRef } from 'react';

export const CinemaViewerContainer = forwardRef<
  HTMLDivElement,
  CinemaViewerContainerProps
>(function CinemaViewerContainer({ children, className }, ref) {
  const { isCinema } = useCinema();

  return (
    <div
      ref={ref}
      className={cn(
        'not-prose overflow-hidden rounded-md border border-border',
        {
          'super-wrapper mux-video bg-background': !isCinema,
          'bg-background/50 backdrop-blur-md': isCinema,
        },
        className
      )}
      style={
        isCinema
          ? {
              position: 'fixed',
              top: 0,
              overflow: 'hidden',
              left: 0,
              right: 0,
              bottom: 0,
              maxHeight: '100vh',
              zIndex: 9999,
              marginBottom: '0 !important',
              borderRadius: 0,
            }
          : undefined
      }
    >
      <div
        className={cn({
          'flex w-full items-center justify-center p-4 lg:p-8 h-full': isCinema,
        })}
      >
        <div
          className={cn('overflow-hidden h-fit w-full flex-1 gap-2', {
            'rounded-md': isCinema,
          })}
          style={
            isCinema
              ? { maxWidth: '1200px', maxHeight: 'calc(90vh - 32px)' }
              : undefined
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
});
